import React from "react";
// Sections
import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Team from "../components/Sections/Team";
import Footer from "../components/Sections/Footer"
import { FloatButton } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import demo from "../assets/img//demonstration.jpg"
import styled from "styled-components";


export default function Landing() {
  return (
    <>
      <TopNavbar />
      <Header />
      <Services />
      <Team />
      <Footer />
      </>
  );
}

