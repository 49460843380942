import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Assets
import ClientLogo01 from "../../assets/img/clients/java.png";
import ClientLogo02 from "../../assets/img/clients/react.png";
import ClientLogo03 from "../../assets/img/clients/devops.png";
import ClientLogo04 from "../../assets/img/clients/android.png";
import ClientLogo05 from "../../assets/img/clients/material.svg";
import ClientLogo06 from "../../assets/img/clients/microchip.png";


export default function ClientSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Shows 6 slides at a time
    slidesToScroll: 1, // Scrolls 2 slides at a time
    arrows: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <SliderWrapper>
      <Slider {...settings}>
        <LogoWrapper>
          <ImgStyle src={ClientLogo01} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo02} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo03} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo04} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo05} alt="client logo" />
        </LogoWrapper>
        <LogoWrapper>
          <ImgStyle src={ClientLogo06} alt="client logo" />
        </LogoWrapper>
      </Slider>
    </SliderWrapper>
  );
}

const SliderWrapper = styled.div`
  .slick-slide {
    display: flex;
    justify-content: center;
  }
      height: 50px;
  .slick-track {
    display: flex;
    align-items: center;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

const ImgStyle = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  padding: 10%;
`;
