import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, message } from 'antd';
import emailjs from 'emailjs-com';

export default function ContactPopup({ userEmail, userContact, isOpen, onClose }) {
  const [email, setEmail] = useState(userEmail || '');
  const [contact, setContact] = useState(userContact || '');
  const [Message, setMessage] = useState('');
  const [messageApi, contextHolder] = message.useMessage();

  const sendEmail = (e) => {
    e.preventDefault();

    const templateParams = {
      from_email: email,
      contact_info: contact,
      message: Message,
      reply_to: email
    };

    emailjs.send('service_ecimmof', 'template_y0oodye', templateParams, 'MTtQFA6y_fIz4ziaW')
      .then((response) => {
        messageApi.open({
          type: 'success',
          content: 'Email Sent Successfully',
        });
        onClose(); 
      })
      .catch((err) => {
        messageApi.open({
          type: 'error',
          content: 'Failed to send email',
        });
        onClose(); 
      });
  };

  return (
    <>
      {contextHolder}
      <Modal
        title="Contact Us"
        open={isOpen}
        onCancel={onClose}
        footer={null} // Remove default footer
      >
        <form onSubmit={sendEmail}>
          <Input
            type="email"
            placeholder="Your Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <Input
            type="text"
            placeholder="Your phone number"
            value={contact}
            onChange={(e) => setContact(e.target.value)}
            required
          />
          <Textarea
            placeholder="Your Message"
            value={Message}
            onChange={(e) => setMessage(e.target.value)}
            required
          />
          <SendButton type="submit">Send</SendButton>
        </form>
      </Modal>
    </>
  );
}

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
`;

const Textarea = styled.textarea`
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  border: 1px solid #ccc;
  resize: none;
`;

const SendButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
`;
