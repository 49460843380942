import React from "react";
import styled from "styled-components";
import beatrice from "../../assets/img/beatrice.jpeg"
import levy from "../../assets/img/levy1.jpeg"
import meshak from "../../assets/img/meshak.jpeg"
import sharon from "../../assets/img/wacheke1.jpeg"
import birgen from "../../assets/img/Sharon.jpg"
import joseph from "../../assets/img/joseph.jpeg"
import defaultImage from "../../assets/img/man.png"

export default function TestimonialBox({ text, author, title }) {
  const getImage = author === "Beatrice Omolo" 
  ? beatrice 
  : author === "Joseph Mbera" 
  ? joseph 
  : author === "Sharon Wacheke" 
  ? sharon :author === "Levy Wanyonyi" 
  ? levy:author === "Meshack Otieno" 
  ? meshak:author === "Sharon Birgen" 
  ? birgen   
  : defaultImage;
  return (
    <Wrapper className="darkBg radius8 flexNullCenter flexColumn">
        <QuoteWrapper>
        <Img className="radius8" src={getImage} alt={author} /> 
                </QuoteWrapper>
        <p className="whiteColor font13" style={{ paddingBottom: "20px",paddingTop:0,}}>
          {text}
        </p>
        <p className="orangeColor font13" style={{ alignSelf: 'flex-end' }}>
          <em>{author}</em>
        </p>
        <p className="orangeColor font13" style={{ alignSelf: 'flex-end', fontSize: 10 }}>
          <em>{title}</em>
        </p>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  height:280px;
  padding: 20px 30px;
  margin-top: 60px;
`;

const QuoteWrapper = styled.div`
  position: relative;
  top: -50px;
  height:100px;
`;

const Img = styled.img`
  max-width: 120px;  /* Adjust this value as needed */
  width: 100%;
  height: 140px;
  border-radius: 30%; /* Optional: make image circular */
  @media (max-width: 560px) {
    max-width: 100px; /* Adjust for smaller screens */
  }
    
`;