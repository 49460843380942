import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";
import { Typography } from "antd";
import LogoImg from "../../assets/img/logo without bg.png";
import { LinkedinOutlined, MailOutlined, PhoneOutlined, TikTokOutlined, TwitterOutlined, WhatsAppOutlined, YoutubeOutlined } from "@ant-design/icons";
const { Title} = Typography;

export default function Contact() {

  const getCurrentYear = () => {
    return new Date().getFullYear();
  }

  return (
    <Wrapper>
      <div style={{backgroundColor:"#0B093B"}}>
        <div className="container">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0",height:10 }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              {/* <LogoImg /> */}
              <Img className="radius8" src={LogoImg} alt={"medicinachain"} /> 

              <h1 className="font15 extraBold whiteColor" style={{ marginLeft: "15px" }}>
                Medicinachain
              </h1>
            </Link>
            <div className="social-media-icons">
              <a href="https://x.com/medicinachain" target="_blank" rel="noopener noreferrer" className="social-icon-link">
                <TwitterOutlined className="social-icon" style={{ color: '#1DA1F2',marginRight:10 }} />
              </a>
              <a href="https://www.linkedin.com/company/medicinachain/" target="_blank" rel="noopener noreferrer" className="social-icon-link">
                <LinkedinOutlined className="social-icon" style={{ color: '#0077b5',marginRight:10 }} />
              </a>
              <a href="https://whatsapp.com/channel/0029VahwR3f8qIzm2uYO5Q3Z" target="_blank" rel="noopener noreferrer" className="social-icon-link">
                <WhatsAppOutlined className="social-icon" style={{ color: '#25D366',marginRight:10 }} />
              </a>
              <a href="https://www.tiktok.com/@medicinachain?_t=8nFFWiyEwFi&_r=1" target="_blank" rel="noopener noreferrer" className="social-icon-link">
                <TikTokOutlined className="social-icon" style={{ color: '#000000',marginRight:10 }} />
              </a>
              <a href="https://youtube.com/@medicinachain?si=cNFRIkLHVzG1HMAp" target="_blank" rel="noopener noreferrer" className="social-icon-link">
                <YoutubeOutlined className="social-icon" style={{ color: '#FF0000',marginRight:10 }} />
              </a>
            </div>
            <StyleP className="whiteColor font13">
             <a  href="tel:+254729322119"style={{marginRight:10,color:"white"}} ><PhoneOutlined className="social-icon" style={{ color: '#1DA1F2'}}/></a>
             <a href="mailto:info@medicinachain.com" style={{marginRight:10,color:"white"}} ><MailOutlined className="social-icon" style={{ color: '#FF0000'}}/></a>
            </StyleP>
            <StyleP className="whiteColor font13">
              © {getCurrentYear()} - <span className="purpleColor font13">Medicinachain</span> All Right Reserved
            </StyleP>
            <Link className="whiteColor animate pointer font13" to="home" smooth={true} offset={-80}>
              Back to top
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
const Img = styled.img`
  max-width: 100px;  /* Adjust this value as needed */
  width: 100%;
  height: 30px;
  transform-style: preserve-3d;
  animation: rotate 5s infinite linear;

  @media (max-width: 560px) {
    max-width: 100px; /* Adjust for smaller screens */
  } 

  @keyframes rotate {
    from {
      transform: rotateY(0deg);
    }
    to {
      transform: rotateY(360deg);
    }
  }
`;
